import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import $ from 'jquery';
import { CommonService } from 'src/app/services/common/common.service';
import { apiList } from 'src/app/services/https/api-list';
import { HttpsService } from 'src/app/services/https/https.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  docs:any[] = [];
  currentYear: number;

  constructor(private common: CommonService, private https: HttpsService, private apiList: apiList, private router: Router, private route: ActivatedRoute) {

  }

  getCurrentYear() {
    let date = new Date();
    console.log(date.getFullYear());
    this.currentYear = date.getFullYear();
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $('#scroll').fadeIn();
        } else {
          $('#scroll').fadeOut();
        }
      });
      $('#scroll').click(function () {
        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;
      });
    });
    this.getData();
  }

  getData() {
    this.https.httpPostWithHeader(this.apiList.getCms, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.docs = res.data.cmsData;
      }
    });
  }

}

<!-- prescription start -->
<!-- <div class="prs">
        <div class="conta_iner">
            <div class="prsm">
                <div class="prtext">
                    <h5>A STORE FOR YOUR ALL NEEDS</h5>
                </div>
                <div class="cross">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    </div> -->
<!-- prescription end -->
<!-- banner start -->
<!-- <div class="banner">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <div class="banner-main">
                    <div class="bleft">
                        <figure><img src="{{ slide.img }}" alt="" width="100%"></figure>
                    </div>
                    <div class="bright">
                        <h1><b>{{slide.off}}</b> Off</h1>
                        <div class="dmain">
                            <h5>{{slide.dmtop}}</h5>
                            <h6>Use Code:<span> {{slide.dmbottom}}</span></h6>
                        </div>
                        <p>{{slide.para}}</p>
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
    </div> -->
<div class="prs">
    <div class="conta_iner">
        <div class="prsm">
            <div class="prtext">
                <h5>{{tagLine}}</h5>
            </div>
        </div>
    </div>
</div>
<div class="banner" id="bannerImage">
    <figure>
        <img [src]="banner">
    </figure>
    <div class="bn-ovl"></div>
</div>
<!-- banner end -->
<!-- category start -->
<section class="category">
    <div class="conta_iner">
        <!-- <div class="title-bar">
                <h2>Categories</h2>
            </div> -->
        <div class="ct-slider">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig1">
                <div ngxSlickItem *ngFor="let category of categories" class="slide">
                    <div class="cateInner">
                        <figure (click)="goToProducts(category)">
                            <img src="{{ category.image }}" alt="Category">
                        </figure>
                        <h5>{{ category.name }}</h5>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section>
<!-- category end -->

<!-- best seller start -->
<section class="seller">
    <div class="conta_iner">
        <div class="title-bar">
            <h2>Recommended</h2>
        </div>
        <div class="ct-slider">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig2">
                <div ngxSlickItem *ngFor="let item of recommended" class="slide">
                    <div class="productRecomment">
                        <div class="offp" *ngIf="item?.discount">
                            <div class="off">
                                <h5>{{item?.discount | number:'1.1-1'}}% off</h5>
                            </div>
                        </div>
                        <figure *ngIf="!item.images || item.images.length === 0" (click)="goToProduct(item)">
                            <img src="{{ item.image }}" alt="seller">
                        </figure>
                        <ngx-slick-carousel *ngIf="item.images && item.images.length > 0" #slickModal="slick-carousel"
                            [config]="slideConfigImg">
                            <div ngxSlickItem class="slide">
                                <figure>
                                    <img [src]="item.image" alt="Product Main Image" (click)="goToProduct(item)">
                                </figure>
                            </div>
                            <div *ngFor="let image of item.images; let i = index" ngxSlickItem class="slide">
                                <figure>
                                    <img [src]="image" [attr.alt]="'Product Image ' + (i + 1)" (click)="goToProduct(item)">
                                </figure>
                            </div>
                        </ngx-slick-carousel>

                        <h5 (click)="goToProduct(item)" style="color: rgb(59, 58, 58); font-weight: bold;">
                            {{item.name}}</h5>
                            <h6 style="color: rgb(88, 87, 87);">₹ {{item?.sellingPrice}} <small>Per {{ item?.altUnit == item?.units ? item?.altUnit : item?.altUnit+"/"+item?.units}}</small></h6>
                            <div class="reMain">
                            <div (click)="goToProduct(item)" class="rLeft">
                                <h6>₹{{item?.sellingPrice*item?.quantity | number:'1.2-2'}}</h6>
                                <h6 *ngIf="item?.discount"><del style="color: gray;">₹{{item?.marketPrice*item?.quantity}}</del></h6>
                            </div>
                            <div class="rRight">
                                <button *ngIf="!checkIfCartItem(item._id)" (click)="addToCart(item._id)">Add
                                    +</button>
                            </div>
                            <div [class]="checkIfCartItem(item._id)?'ppricer':'dsp-none'">
                                <button class="nbr" *ngIf="checkIfCartItem(item._id)"
                                    (click)="removeFromCart(item._id)">-</button>
                                <input disabled *ngIf="checkIfCartItem(item._id)"
                                    style="width: 40px;border: 1.2px solid #80808047;text-align: center;"
                                    [value]="checkIfCartItem(item._id).itemQuantity" type="text" />
                                <button class="nbl" *ngIf="checkIfCartItem(item._id)"
                                    (click)="addToCart(item._id)">+</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section>
<!-- best seller end -->


<!-- best seller start -->
<section class="seller" style="padding-top: 0">
    <div class="conta_iner">
        <div class="title-bar">
            <h2>Trending</h2>
        </div>
        <div class="ct-slider">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig3">
                <div ngxSlickItem *ngFor="let item of trending" class="slide">
                    <div class="productRecomment">
                        <div class="offp" *ngIf="item?.discount">
                            <div class="off">
                                <h5>{{item?.discount | number:'1.1-1'}}% off</h5>
                            </div>
                        </div>
                        <figure *ngIf="!item.images || item.images.length === 0" (click)="goToProduct(item)">
                            <img src="{{ item.image }}" alt="seller">
                        </figure>
                        <ngx-slick-carousel *ngIf="item.images && item.images.length > 0" #slickModal="slick-carousel"
                            [config]="slideConfigImg">
                            <div ngxSlickItem class="slide">
                                <figure>
                                    <img [src]="item.image" alt="Product Main Image" (click)="goToProduct(item)">
                                </figure>
                            </div>
                            <div *ngFor="let image of item.images; let i = index" ngxSlickItem class="slide">
                                <figure>
                                    <img [src]="image" [attr.alt]="'Product Image ' + (i + 1)" (click)="goToProduct(item)">
                                </figure>
                            </div>
                        </ngx-slick-carousel>
                        <h5 (click)="goToProduct(item)" style="color: rgb(59, 58, 58); font-weight: bold;">
                            {{item.name}}</h5>
                        <h6 style="color: rgb(88, 87, 87);">₹ {{item?.sellingPrice}} <small>Per {{ item?.altUnit == item?.units ? item?.altUnit : item?.altUnit+"/"+item?.units}}</small></h6>
                        <div class="reMain">
                            <div (click)="goToProduct(item)" class="rLeft">
                                <h6>₹{{item?.sellingPrice*item?.quantity | number:'1.2-2'}}</h6>
                                <h6 *ngIf="item?.discount"><del style="color: gray;">₹{{item?.marketPrice*item?.quantity | number:'1.2-2'}}</del></h6>
                            </div>
                            <div class="rRight">
                                <button *ngIf="!checkIfCartItem(item._id)" (click)="addToCart(item._id)">Add
                                    +</button>
                            </div>
                            <div [class]="checkIfCartItem(item._id)?'ppricer':'dsp-none'">
                                <button class="nbr" *ngIf="checkIfCartItem(item._id)"
                                    (click)="removeFromCart(item._id)">-</button>
                                <input disabled *ngIf="checkIfCartItem(item._id)"
                                    style="width: 40px;border: 1.2px solid #80808047;text-align: center;"
                                    [value]="checkIfCartItem(item._id).itemQuantity" type="text" />
                                <button class="nbl" *ngIf="checkIfCartItem(item._id)"
                                    (click)="addToCart(item._id)">+</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section>
<!-- best seller end -->
<section [class]="cat?.products?.length>4?'seller':'no0show'" style="padding-top: 0" *ngFor="let cat of productCategory">
    <div class="conta_iner">
        <div class="title-bar">
            <h2>{{cat?.name}}</h2>
        </div>
        <div class="ct-slider">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig3">
                <div ngxSlickItem *ngFor="let item of cat?.products" class="slide">
                    <div class="productRecomment">
                        <div class="offp" *ngIf="item?.discount">
                            <div class="off">
                                <h5>{{item?.discount | number:'1.1-1'}}% off</h5>
                            </div>
                        </div>
                        <figure *ngIf="!item.images || item.images.length === 0" (click)="goToProduct(item)">
                            <img src="{{ item.image }}" alt="seller">
                        </figure>
                        <ngx-slick-carousel *ngIf="item.images && item.images.length > 0" #slickModal="slick-carousel"
                            [config]="slideConfigImg">
                            <div ngxSlickItem class="slide">
                                <figure>
                                    <img [src]="item.image" alt="Product Main Image" (click)="goToProduct(item)">
                                </figure>
                            </div>
                            <div *ngFor="let image of item.images; let i = index" ngxSlickItem class="slide">
                                <figure>
                                    <img [src]="image" [attr.alt]="'Product Image ' + (i + 1)" (click)="goToProduct(item)">
                                </figure>
                            </div>
                        </ngx-slick-carousel>
                        <h5 (click)="goToProduct(item)" style="color: rgb(59, 58, 58); font-weight: bold;">
                            {{item.name}}</h5>
                       
                        <h6 style="color: rgb(88, 87, 87);">₹ {{item?.sellingPrice}} <small>Per {{ item?.altUnit == item?.units ? item?.altUnit : item?.altUnit+"/"+item?.units}}</small></h6>
                        <div class="reMain">
                            <div (click)="goToProduct(item)" class="rLeft">
                                <h6>₹{{item?.sellingPrice*item?.quantity | number:'1.2-2'}}</h6>
                                <h6 *ngIf="item?.discount"><del style="color: gray;">₹{{item?.marketPrice*item?.quantity | number:'1.2-2'}}</del></h6>
                            </div>
                            <div class="rRight">
                                <button *ngIf="!checkIfCartItem(item._id)" (click)="addToCart(item._id)">Add
                                    +</button>
                            </div>
                            <div [class]="checkIfCartItem(item._id)?'ppricer':'dsp-none'">
                                <button class="nbr" *ngIf="checkIfCartItem(item._id)"
                                    (click)="removeFromCart(item._id)">-</button>
                                <input disabled *ngIf="checkIfCartItem(item._id)"
                                    style="width: 40px;border: 1.2px solid #80808047;text-align: center;"
                                    [value]="checkIfCartItem(item._id).itemQuantity" type="text" />
                                <button class="nbl" *ngIf="checkIfCartItem(item._id)"
                                    (click)="addToCart(item._id)">+</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section>


<!-- best seller start -->
<!-- <section class="seller">
        <div class="conta_iner">
            <div class="title-bar">
                <h2>Hot Deals</h2>
            </div>
            <div class="ct-slider">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig2">
                    <div ngxSlickItem *ngFor="let seller of sellers" class="slide">
                        <div class="productRecomment">

                            <figure><img src="{{ seller.img }}" alt="seller"></figure>
                            <h5 style="text-align: start;color: rgb(59, 58, 58);">{{seller.cathead}}</h5>
                            <div class="reMain">
                                <div class="rLeft">
                                    <h6>{{seller.price}}</h6>
                                </div>
                                <div class="rRight">
                                    <button>Add +</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </section> -->



<section class="testimonials">
    <div class="conta_iner">
       <div class="title-bar" ><h2>What our clients say?</h2></div>
        <div class="ct-slider">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="testConfig">
                <div ngxSlickItem *ngFor="let item of reviews" class="slide">
                    <div class="tmain">
                        <div class="tlr">
                        <div class="tleft">
                            <figure><img src="{{ item?.userId?.image || 'assets/image/svg/user.svg' }}" alt="seller">
                            </figure>
                        </div>
                        <div class="tright">
                            <h5>{{item?.userId?.name}}</h5>
                            <h6>Customer</h6>
                            <ul class="star">
                                <li><i class="fa fa-star" *ngIf="item?.rating>=1" aria-hidden="true"></i>
                                </li>
                                <li><i class="fa fa-star" *ngIf="item?.rating>=2" aria-hidden="true"></i>
                                </li>
                                <li><i class="fa fa-star" *ngIf="item?.rating>=3" aria-hidden="true"></i>
                                </li>
                                <li><i class="fa fa-star" *ngIf="item?.rating>=4" aria-hidden="true"></i>
                                </li>
                                <li><i class="fa fa-star" *ngIf="item?.rating===5" aria-hidden="true"></i>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <p [ngClass]="{'limitTextHeight': isReadMore}" [innerHTML]="item?.message">{{item?.message}}</p>
                        <div *ngIf="item?.message.length >= 300">
                        <button type="button" (click)="isReadMore=!isReadMore" class="readMore" >
                            {{ isReadMore ? 'Read More': 'Read Less' }}
                        </button>
                    </div>
                
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section>

<section class="servicesSec">
    <div class="conta_iner">
        <div class="services">
            <ul>
                <li>
                    <figure>
                        <img src="assets/image/superfast.png">
                    </figure>
                    <h4>Superfast Delivery</h4>
                    <p>Get your order delivered to your doorstep at the earliest from dark stores near you.</p>
                </li>
                <li>
                    <figure>
                        <img src="assets/image/offerPrice.png">
                    </figure>
                    <h4>Best Prices & Offers</h4>
                    <p>Cheaper prices than your local supermarket, great cashback offers to top it off.</p>
                </li>
                <li>
                    <figure>
                        <img src="assets/image/food.png">
                    </figure>
                    <h4>Wide Assortment
                    </h4>
                    <p>Choose from 5000+ products across food, personal care, household & other categories</p>
                </li>
            </ul>
        </div>
    </div>
</section>
<div class="banner" id="bannerImage">
    <figure>
        <img [src]="prodBanner">
    </figure>
    <div class="bn-ovl"></div>
</div>
<div id="scroll" >
    <span>^</span>
</div>
<footer>
    <div class="conta_iner">
        <div class="fm">
            <div class="fleft">
                <div class="fl1">
                    <figure>
                        <img src="assets/image/logo.jpeg" alt="Logo">
                    </figure>
                </div>
                <div class="fl2">
                    <div class="fl2a">
                        <h5>Policies</h5>
                        <ul>
                            <li *ngFor="let item of docs"><a [routerLink]="'/cms/'+item._id">{{item?.key}}</a></li>
                        </ul>
                    </div>
                    <div class="fl2b">
                        <h5>Contact us</h5>
                        <ul>
                            <li> <span>EMAIL</span>saraswatitraders.com@gmail.com</li>
                            <li> <span>Office</span><ul>
                                <li>• +91 7717645591</li>
                                <li>• +91 8360234960</li>
                                <li>• +91 8559020972</li>
                                <li>• +91 9988500972</li>
                            </ul></li>
                            <!-- <li> <span>Dhruv</span>+91 8559020972</li> -->
                            <!-- <li> <span>Shivam</span>+91 9988500972</li> -->
                            <!-- <li> <span>Address</span>SCF 299 & 106, Grain Market, Sector 26 , Chandigarh, India - 160019</li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="fright">
                <div class="install-ic">
                    <!-- <figure>
                        <img src="assets/image/svg/android.png" alt="Android">
                    </figure>
                    <figure>
                        <img src="assets/image/svg/ios.png" alt="IOS">
                    </figure> -->
                </div>
                <div class="follow-ic">
                    <figure>
                        <img src="assets/image/svg/ic_facebook.svg" alt="Facebook">
                    </figure>
                    <figure>
                        <img src="assets/image/svg/ic_insta.svg" alt="Instagram">
                    </figure>
                    <figure>
                        <img src="assets/image/svg/ic_twitter.svg" alt="Twitter">
                    </figure>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="pre-footer">
   <p class="cright">c</p>
    <p>{{currentYear}} Saraswati Traders.  All rights reserved</p>
</div>
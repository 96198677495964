import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';
import { Overlay } from '@angular/cdk/overlay';
import { LocationStrategy, PathLocationStrategy, CurrencyPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { apiList } from './services/https/api-list';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service';
import {MatIconModule} from '@angular/material/icon';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CmsComponent } from './cms/cms.component';
// import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    CmsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    SharedModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSelectModule,
    MatFormFieldModule,
    MatRadioModule,
    MatTabsModule,
    MatIconModule,
    HttpClientModule,
    NgxSliderModule,
    PdfViewerModule,
    GooglePlaceModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDe5zDWD2AWyXYxd-plbHgBETx09jB0UcE',
    //   libraries: ["drawing", "places", "geometry"]
    // })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    apiList,
    CurrencyPipe,
    // NgxUiLoaderService,
    // MatDialog,
    Overlay,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<header [ngClass]="isCatHeader?'':'hm-shd'">
    <div class="conta_iner">
        <div class="hm">
            <div class="hl">
                <figure routerLink="/home">
                    <img src="assets/image/logo(2).png" alt="Logo">
                </figure>
            </div>
            <div [class]="isTagline?'cat-header desktop':'cat-top cat-header desktop'"
                *ngIf="isCatHeader&&categories&&categories.length">
                <div class="mainhd">
                    <div *ngFor="let item of categories;let i=index" [ngClass]="i>3?'dsp-none':'cntil'"
                        routerLink="/products/{{item.name}}/{{item._id}}">
                        <span>{{item.name}}</span>
                    </div>
                    <div [ngClass]="'cntil ctgspl'" *ngIf="categories.length&&categories.length>3"
                        (click)="showMore=!showMore">
                        <span style="font-weight: 500;font-size: 14px;">More <mat-icon>keyboard_arrow_down</mat-icon>
                        </span>
                        <div [class]="showMore?'ovfr':'dsp-none'">
                            <div *ngFor="let item of categories;let i=index" [ngClass]="i>3?'cntil':'dsp-none'"
                                routerLink="/products/{{item.name}}/{{item._id}}">
                                <span>{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hm2 search-container" #searchBox>
                <input [class]="search ? 'no-brdr' : ''" type="text" placeholder="Search here for anything…." [(ngModel)]="search"
                    (ngModelChange)="searchTerm$.next($event)">
            
                <div class="searchResults" *ngIf="products.length && search">
                    <span *ngFor="let item of products" (click)="goToProduct(item)">{{item?.name}}</span>
                </div>
            <div class="searchResults" *ngIf="!products.length && search">
                    <span style="color: rgba(128, 128, 128, 0.753);">No Products Found.</span>
                </div>
            </div>

            <div class="hrlog" *ngIf="!isLoggedIn">
                <div class="btns">
                    <button class="" routerLink="/login">Login/</button>
                    <button class="activebtn" routerLink="/signup">Signup</button>
                </div>
            </div>
            <div class="hr">
                <div class="hrbox hamburger" (click)="toggleFun($event)">
                    <div>
                        <span style="font-weight: 500; font-size: 14px;">
                            <i class="fa fa-solid fa-bars" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="isLoggedIn" class="endData">
                    <!-- <div class="hrbox">
                    <div class="cartBtn">
                        <i class="fa fa-bell" aria-hidden="true"></i>
                        <b *ngIf="cartLength">{{cartLength}}</b>
                    </div>
                </div> -->
                    <div class="hrbox" routerLink="/cart">
                        <div [ngClass]="'cntil ctgspl no-hvr'">
                            <div class="cartBtn">
                                <i [class]="cartLength?'fa fa-shopping-cart ft33s':'fa fa-shopping-cart'"
                                    aria-hidden="true"></i>
                                <b *ngIf="cartLength">{{cartLength}}</b>
                            </div>
                        </div>
                    </div>
                    <div class="hrbox">
                        <div [ngClass]="'cntil ctgspl no-hvr'">
                            <div (click)="isShown=!isShown" class="userDiv">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                <div class="userDivName"><h4>{{name}}</h4></div>
                            </div>
                            <div [class]="isShown?'ovfr':'dsp-none'">
                                <div>
                                    <span (click)="isShown=!isShown" class="cntil" style="display: block;padding: 0;"
                                        routerLink="/orders">
                                        <div class="dpm">
                                            <div class="dpl">
                                                <!-- <figure>
                                                <img src="assets/image/svg/bookmark1.svg">
                                            </figure> -->
                                                <mat-icon>receipt</mat-icon>
                                            </div>
                                            <div class="dpr">
                                                <h5>My Orders</h5>
                                            </div>
                                        </div>
                                    </span>
                                    <span (click)="isShown=!isShown" class="cntil" style="display: block;padding: 0;"
                                        routerLink="/profile">
                                        <div class="dpm">
                                            <div class="dpl">
                                                <!-- <figure>
                                                <img src="assets/image/svg/bookmark1.svg">
                                            </figure> -->
                                                <mat-icon>account_circle</mat-icon>
                                            </div>
                                            <div class="dpr">
                                                <h5>My Account</h5>
                                            </div>
                                        </div>
                                    </span>
                                    <span class="cntil" style="display: block;padding: 0;"
                                        (click)="isShown=!isShown;logout()">
                                        <div class="dpm">
                                            <!-- <div class="dpl">
                                            <figure>
                                                <img src="assets/image/svg/bookmark1.svg">
                                            </figure>
                                        </div> -->
                                            <mat-icon>power_settings_new</mat-icon>
                                            <div class="dpr">
                                                <h5>Signout</h5>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<!-- <div class="hideReponsive">
<div [class]="isTagline?'cat-header':'cat-top cat-header'" *ngIf="isCatHeader&&categories&&categories.length">
    <div class="mainhd">
        <div *ngFor="let item of categories;let i=index" [ngClass]="i>5?'dsp-none':'cntil'" routerLink="/products/{{item.name}}/{{item._id}}">
            <span>{{item.name}}</span>
        </div>
        <div [ngClass]="'cntil ctgspl'" *ngIf="categories.length&&categories.length>6" (click)="showMore=!showMore">
            <span style="font-weight: 500;font-size: 14px;">More <mat-icon>keyboard_arrow_down</mat-icon></span>
            <div [class]="showMore?'ovfr':'dsp-none'">
                <div *ngFor="let item of categories;let i=index" [ngClass]="i>5?'cntil':'dsp-none'" routerLink="/products/{{item.name}}/{{item._id}}">
                    <span>{{item.cathead}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
</div> -->

<!-- <div *ngIf="categoryShow" class="displayReponsive">
    <div [class]="isTagline?'cat-header':'cat-top cat-header'" *ngIf="isCatHeader&&categories&&categories.length">
        <div class="mainhd">
            <div *ngFor="let item of categories;let i=index" [ngClass]="i>3?'dsp-none':'cntil'" (click)="toggleFun()"
                routerLink="/products/{{item.name}}/{{item._id}}">
                <span>{{item.name}}</span>
            </div>
            <div [ngClass]="'cntil ctgspl'" *ngIf="categories.length&&categories.length>3" (click)="showMore=!showMore">
                <span style="font-weight: 500;font-size: 14px;">More <mat-icon>keyboard_arrow_down</mat-icon></span>
                <div [class]="showMore?'ovfr':'dsp-none'">
                    <div *ngFor="let item of categories;let i=index" [ngClass]="i>3?'cntil':'dsp-none'"
                        routerLink="/products/{{item.name}}/{{item._id}}">
                        <span>{{item.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div *ngIf="categoryShow" class="displayResponsive" (clickOutside)="onOutsideClick()">
    <div class="cat-header">
        <button (click)="toggleDropdown()" class="dropdown-button mt-3">
            <span>Categories</span>
            <mat-icon>{{ showDropdown ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
        <div *ngIf="showDropdown">
            <div *ngFor="let item of categories; let i = index" [ngClass]="i > 3 ? 'dsp-none' : 'cntil'"
                (click)="toggleFun(); toggleDropdown()" [routerLink]="'/products/' + item.name + '/' + item._id">
                <span>{{ item.name }}</span>
            </div>
        </div>
        <div [ngClass]="'cntil ctgspl'" *ngIf="categories.length && categories.length > 3 && showDropdown"
            (click)="showMore=!showMore">
            <span style="font-weight: 500;font-size: 14px;">More <mat-icon>keyboard_arrow_down</mat-icon></span>
        </div>
        <div [class]="showMore ? 'cntil' : 'dsp-none'" (click)="toggleFun(); toggleDropdown(); showMore=!showMore">
            <div *ngFor="let item of categories; let i = index" [ngClass]="i > 3 ? 'cntil' : 'dsp-none'"
                routerLink="/products/{{item.name}}/{{item._id}}">
                <span>{{ item.name }}</span>
            </div>
        </div>

        <button (click)="toggleDropdownAcc()" class="dropdown-button mt-3">
            <span>Account</span>
            <mat-icon>{{ showAccount ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
        <div *ngIf="showAccount" class="">
            <div class="hrlog" style="width: 133px;" *ngIf="!isLoggedIn" (click)="toggleFun(); toggleDropdownAcc()">
                <div class="btns">
                    <button class="" routerLink="/login">Login/</button>
                    <button class="activebtn" routerLink="/signup">Signup</button>
                </div>
            </div>
            <div *ngIf="isLoggedIn" class="cntil" routerLink="/orders" (click)="toggleFun(); toggleDropdownAcc()">
                <span>My Orders</span>
            </div>
            <div *ngIf="isLoggedIn" class="cntil" routerLink="/cart" (click)="toggleFun(); toggleDropdownAcc()">
                <span>Cart</span>
            </div>
            <div *ngIf="isLoggedIn" class="cntil" routerLink="/profile" (click)="toggleFun(); toggleDropdownAcc()">
                <span>My Account</span>
            </div>
            <div *ngIf="isLoggedIn" class="cntil" style="border-bottom: 1px solid #ddd" routerLink="/profile"
                (click)="toggleFun(); toggleDropdownAcc(); logout()">
                <span (click)="logout()">Signout</span>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common/common.service';
import { apiList } from '../services/https/api-list';
import { HttpsService } from '../services/https/https.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  services:any[] = [];
  tagLine!: string;
  homeBanner!: string;
  productBanner!: string;
  slides:any[] = [
    { img: "assets/image/banner1.png", off: '25%', dmtop: 'on all products.', dmbottom: 'DISCOUNT123', para: '* cash on delivery only' },
  ];
  testimonials = [
    { img: "https://texantreeexperts.com/wp-content/uploads/2018/01/testimonial-image-female.jpg", name: 'Neha', position: 'ABC', para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
    { img: "https://www.findtherightclick.com/wp-content/uploads/2017/07/Matt-T-Testimonial-pic.jpg", name: 'John', position: 'ABC', para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
    { img: "https://texantreeexperts.com/wp-content/uploads/2018/01/testimonial-image-female.jpg", name: 'Neha', position: 'ABC', para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
  ];
  desktopSlideConfig: any = {
    slidesToShow: 9,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
  };

  mobileSlideConfig: any = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    rows: 2, // 2 rows for mobile view
  };
  slideConfig:any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
  
  };
  slideConfig1:any = {
    slidesToShow:9,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    column:2,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 8
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
    ]
  };
  slideConfig2:any = {
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          
        }
      },
    ]
  };
  slideConfig3:any = {
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  testConfig:any = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        }
      }
    ]
  };
  slideConfigImg = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true
  }

  categories: any[] = [];
  recommended: any[] = [];
  trending: any[] = [];
  count: number = 0;
  cart: any[] = [];
  banner: string;
  prodBanner: string;
  reviewsCount: number = 0;
  reviews: any[] = [];
  productCategory:any [] =[];
  constructor(private common: CommonService, private https: HttpsService, private apiList: apiList, private router: Router, private route: ActivatedRoute, private breakpointObserver: BreakpointObserver) { }

  getData() {
    const pendingCartItem = localStorage.getItem('pendingCartItem');
    this.https.httpPostWithHeader(this.apiList.getCategory, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.categories = res.data.CategoryData;
        this.count = res.data.CategoryCount;
      }
    });
    if (pendingCartItem) {
      const data = JSON.parse(pendingCartItem);
      this.https.httpPostWithHeader(this.apiList.addToCart, data).subscribe((res: any) => {
        if (res && res.statusCode && res.statusCode === 200) {
          this.common.presentsToast('success', 'top-end', res.message);
          this.getCart();
          localStorage.removeItem('pendingCartItem'); // Clear the pending item after it is added
        }
      });
    }
  }

  getLandingPage() {
    this.https.httpGetWithHeader(this.apiList.getLandingPage, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.tagLine = res.data.tagLine;
        this.banner = res.data.homeBanner;
        this.prodBanner = res.data.productsBanner;
        console.log("res.data",res.data)
      }
    });
  }

  getReviews() {
    this.https.httpGetWithHeader(this.apiList.getReviews, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.reviews = res.data.reviews;
        this.reviewsCount = res.data.reviewsCount;
      }
    });
  }

  getRecommendedData() {
    this.https.httpPostWithHeader(this.apiList.recommendedProducts, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.recommended = res.data.products;
        this.count = res.data.productsCount;
      }
    });
  }

  getTrendingData() {
    this.https.httpPostWithHeader(this.apiList.trendingProducts, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.trending = res.data.products;
        this.count = res.data.productsCount;
      }
    });
  }

  getProductWithCategory(){
    this.https.httpPostWithHeader(this.apiList.productWithCategory, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
         this.productCategory = res.data.CategoryData;
        this.count = res.data.CategoryCount;
       console.log(res);
       
      }
    });
  }

  goToProducts(item: any) {
    this.router.navigateByUrl("/products/" + item.name + '/' + item._id);
  }

  goToProduct(item: any) {
    let name: string = item.marketingLink;
    // name = name.split(" ") ? name.split(" ")[0] : name;
    this.router.navigateByUrl("/productDetail/" + name);
  }

  getCart() {
    let data = {
    };
    this.https.httpPostWithHeader(this.apiList.getCart, data).subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.cart = res.data.items;
      }
    });
  }

  addToCart(id: string) {
    const isLoggedIn = !!localStorage.getItem('accessToken'); // Check if the user is logged in

    if (!isLoggedIn) {
      // Store the intended action in local storage
      const pendingAction = {
        "removeAll": false,
        "productId": id,
        "removeItem": false,
        "isRemove": false
      };
      localStorage.setItem('pendingCartItem', JSON.stringify(pendingAction));

      // Redirect to login page
      this.router.navigate(['/login']);
    } else {
      let data = {
        "removeAll": false,
        "productId": id,
        "removeItem": false,
        "isRemove": false
      };
      this.https.httpPostWithHeader(this.apiList.addToCart, data).subscribe((res: any) => {
        if (res && res.statusCode && res.statusCode === 200) {
          this.common.presentsToast('success', 'top-end', res.message);
          this.getCart();
        }
      });
    }
  }


  removeFromCart(id: string) {
    let data = {
      "removeAll": false,
      "productId": id,
      "removeItem": false,
      "isRemove": true
    };
    this.https.httpPostWithHeader(this.apiList.addToCart, data).subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.getCart();
        // this.cart = res.data.items;
      }
    });
  }

  checkIfCartItem(id: string) {
    if (this.cart&&this.cart.length) {
      let item = this.cart.find((item: any) => item.productId._id === id);
      if (item) return item;
    }
    return false;
  }

  ngOnInit(): void {
    this.getLandingPage();
    this.getData();
    this.getRecommendedData();
    this.getTrendingData();
    this.getReviews();
    this.getProductWithCategory();
    let token = localStorage.getItem("accessToken");
    token && this.getCart();

    // Initial BreakpointObserver check
    this.updateSlideConfig();

    // Listen to window resize event
    window.addEventListener('resize', () => {
      this.updateSlideConfig();
    });

  }

  updateSlideConfig(): void {
    this.breakpointObserver.observe(['(max-width: 767px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.slideConfig1 = this.mobileSlideConfig;
      } else {
        this.slideConfig1 = this.desktopSlideConfig;
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.updateSlideConfig);
  }

  public isReadMore:boolean = true;


}

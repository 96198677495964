import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common/common.service';
import { apiList } from '../services/https/api-list';
import { HttpsService } from '../services/https/https.service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['../home/home.component.scss','./cms.component.scss']
})
export class CmsComponent implements OnInit {
  id: string;
  pdfSrc: string;
  name: string;

  constructor(private common: CommonService, private https: HttpsService, private apiList: apiList, private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe((res:any) => {
      if(res&&res.id) {
        this.id = res.id;
        this.getData();
      }
    })
  }

  ngOnInit(): void {
  }

  getData() {
    this.https.httpPostWithHeader(this.apiList.getCms+'/'+this.id, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.pdfSrc = res.data.value;
        this.name = res.data.key;
      }
    });
  }

}

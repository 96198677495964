import { Injectable } from "@angular/core";

@Injectable()
export class apiList {

  login: string = "api/v1/user/login";
  verifyOtp: string = "api/v1/user/verifyOtp";
  regiser: string = "api/v1/user/signup";
  logout: string = "api/v1/user/logout";
  getCategory: string = "api/v1/user/getCategory";
  recommendedProducts: string = "api/v1/user/recommendedProducts";
  trendingProducts: string = "api/v1/user/trendingProducts";
  getProduct: string = "api/v1/user/getProducts";
  sendOtp: string = "api/v1/user/sendOtp";
  getCart: string = "api/v1/user/getCart";
  addToCart: string = "api/v1/user/addToCart";
  getCoupons: string = "api/v1/user/getCoupons";
  getProfile: string = "api/v1/user/getProfile";
  updateProfile: string = "api/v1/user/updateProfile";
  updateAddress: string = "api/v1/user/updateAddress";
  deleteProfile: string = "api/v1/user/deleteProfile";
  uploadFile: string = "api/v1/user/uploadFile";
  getCms: string = "api/v1/user/getCms";
  placeOrder: string = "api/v1/user/placeOrder";
  cancelOrder: string = "api/v1/user/cancelOrder";
  addReview: string = "api/v1/user/addReview";
  getReview: string = "api/v1/user/getReview";
  getTags: string = "api/v1/user/getTags";
  
  getLandingPage: string = "api/v1/user/getLandingPage";
  editCategory: string = "api/v1/user/editCategory";
  deleteCategory: string = "api/v1/user/deleteCategory";
  addSubCategory: string = "api/v1/user/addSubCategory";
  editSubCategory: string = "api/v1/user/editSubCategory";
  getSubCategory: string = "api/v1/user/getSubCategory";
  deleteSubCategory: string = "api/v1/user/deleteSubCategory";
  addReviews: string = "api/v1/user/addReviews";
  getReviews: string = "api/v1/user/getReviews";
  deleteReviews: string = "api/v1/user/deleteReviews";
  editSetiing: string = "api/v1/user/editSetiing";
  getSetiing: string = "api/v1/user/getSetiing";
  editOrder: string = "api/v1/user/editOrder";
  getOrder: string = "api/v1/user/getOrder";
  addUsers: string = "api/v1/user/addUsers";
  editUsers: string = "api/v1/user/editUsers";
  updateUsers: string = "api/v1/user/updateUsers";
  getUsers: string = "api/v1/user/getUsers";
  deleteUsers: string = "api/v1/user/deleteUsers";
  addCoupons: string = "api/v1/user/addCoupons";
  deleteCoupons: string = "api/v1/user/deleteCoupons";
  productWithCategory: string = 'api/v1/user/getProductsWithCategory'

}

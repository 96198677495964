import { Component, OnInit, Renderer2, ElementRef, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';
import { apiList } from 'src/app/services/https/api-list';
import { HttpsService } from 'src/app/services/https/https.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

declare const $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('categoryContainer') categoryContainer: ElementRef; // Reference to category container
  isShown: boolean = false;
  isCatHeader:boolean = false;
  isTagline: boolean = true;
  showDropdown: boolean = false;
  showAccount: boolean = false;
  showMoreContent = false;
  showMoreContentDetails = false;
  showMore: boolean = false;
  isLoggedIn: boolean = false;
  categoryId: string;
  searchResults: any[] = [];
  search: string;
  categories: any[] = [];
  count: number = 0;
  products: any[] = [];
  productCount: number = 0;
  image: any;
  name:string;
  tagLine: string;
  categoryShow:boolean = false;
  cartLength:number = 0;

  searchTerm$ = new Subject<string>();
  @ViewChild('searchBox') searchBox: ElementRef; // Add a reference to the search box

  constructor(private common: CommonService, private https: HttpsService, private apiList: apiList, private router: Router, private route: ActivatedRoute, private renderer: Renderer2) {
    // if(location.href.includes('/home')) {
    //   this.isCatHeader = false;
    // } else {
    //   this.isCatHeader = true;
    // }
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe((event: NavigationStart) => {
      // this.isShown = false;
      // this.showMore = false;
      this.isTagline = true;
      if(!localStorage.getItem('accessToken')) {
        this.isCatHeader = false;
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
        this.isCatHeader = true;
      }
      this.isCatHeader = true;
      if(event.url==='/login'||event.url==='/signup') {
        this.isCatHeader = false;
        this.isTagline = false;
      } else {
        // if(event.url==='/home'||event.url==='/') {
        //   this.isCatHeader = false;
        // } else {
        //   this.isCatHeader = true;
        // }
      }
    });
  }
  
  ngOnInit(): void {
    this.common.isCartChanged.subscribe((res:boolean) => {
      if(res) {
        this.common.updateCartValue.next(false);
        this.getCart();
      }
    });
    this.getLandingPage();
    this.getData();
    this.getCart();
    this.getProfile()

    this.searchTerm$.pipe(
      debounceTime(600) // Adjust the debounce time (in milliseconds) as needed
    ).subscribe(term => {
      this.searchProducts(term);
    });

    this.renderer.listen('document', 'click', (event) => {
      this.handleOutsideClick(event);
    });

  $(window).scroll(function() {    
    var scroll = $(window).scrollTop();

    if (scroll >= 200) {
        $(".mainhd").addClass("darkHeader");
    } else {
        $(".mainhd").removeClass("darkHeader");
    }
});
  }

  handleOutsideClick(event: MouseEvent): void {
    if (this.searchBox && !this.searchBox.nativeElement.contains(event.target)) {
      this.closeSearch();
    }
    if (this.categoryContainer && !this.categoryContainer.nativeElement.contains(event.target)) {
      this.categoryShow = false;
      this.showDropdown = false;
      this.showAccount = false;
      this.showMore = false;
    }
  }

  closeSearch() {
    this.search = '';
    this.searchResults = [];
  }

  toggleFun(event?: Event) {
    this.categoryShow = !this.categoryShow;
    if (event) {
      event.stopPropagation();
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  toggleDropdownAcc() {
    this.showAccount = !this.showAccount;
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(event: Event) {
    const element = event.target as HTMLElement;

    // Close categoryShow only if the click is outside the hamburger or the categories section
    if (!element.closest('.hamburger') && !element.closest('.displayResponsive')) {
      this.categoryShow = false;
    }
  }

  toggleMoreContent() {
    this.showMoreContent = !this.showMoreContent;
  }

  getLandingPage() {
    this.https.httpGetWithHeader(this.apiList.getLandingPage, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.tagLine = res.data.tagLine;
      }
    });
  }

  onclickbtn(){
    this.isShown = !this.isShown;
  }

  getProfile() {
    this.https.httpGetWithHeader(this.apiList.getProfile, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.image = res.data.image;
        this.name = res.data.name;
      }
    });
  }

  getCart() {
    let data = {
    }
    this.https.httpPostWithHeader(this.apiList.getCart, data).subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        if(res.data&&res.data.items&&res.data.items.length) {
          let count = 0;
          res.data.items.forEach((element:any) => {
            count = count+element.itemQuantity;
          });
          this.cartLength = count;
        }
      }
    });
  }

  getData() {
    this.https.httpPostWithHeader(this.apiList.getCategory, '').subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        if(res.data.CategoryCount>1) {
          this.categories = res.data.CategoryData;
          this.count = res.data.CategoryCount;
        } else {
          this.isCatHeader = false;
        }
      }
    });
  }

  searchProducts(term: string) {
    let data = {
      search: term
    };
    this.https.httpPostWithHeader(this.apiList.getProduct, data).subscribe((res: any) => {
      if (res && res.statusCode && res.statusCode === 200) {
        this.products = res.data.ProductData;
        this.productCount = res.data.ProductCount;
      }
    });
  }

  goToProduct(item:any) {
    console.log(item)
    let name:string = item.marketingLink;
    // name = name.split(" ")?name.split(" ")[0]:name;
    this.search = "";
    this.router.navigateByUrl("/productDetail/"+name);
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl("/login");
  }

}
